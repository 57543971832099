import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { flatten } from "lodash";

const query = graphql`
  {
    allShopifyCollection(
      sort: { fields: updatedAt }
      filter: {
        handle: {
          in: [
            "covid-related",
            "get-well",
            "new-products",
            "wedding",
            "congratulations",
            "sympathy",
            "encouragement",
            "holiday",
            "birthday",
            "friendship",
            "mothers-day",
            "valentines-day",
            "thank-you",
          ],
        },
      }
    ) {
      nodes {
        id
        title
        slug: handle
        storefrontId
        productsCount
        products {
          id
          slug: handle
          title
          description
          descriptionHtml
          vendor
          images {
            id
            altText
            originalSrc
            src
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          collections {
            slug: handle
          }
        }
      }
    }
  }
`;

export const useCategories = (slugs = []) => {
  const { allShopifyCollection: { nodes } = { nodes: [] } } = useStaticQuery(query);
  const products = useMemo(() => {
    return flatten(
      nodes
        .filter(({ slug }) =>
          slugs.indexOf(slug) >= 0 || !slugs.length
        )
        .map(({ products }) => products)
    );
  }, [nodes, slugs]);

  return { loading: false, products };
}
