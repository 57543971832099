import { graphql, useStaticQuery } from "gatsby";

import { transform } from "./products.utils";

const latestQuery = graphql`
  {
    allShopifyProduct(
      limit: 6,
      sort: {
        fields: publishedAt,
        order: DESC,
      },
    ) {
      nodes {
        id
        slug: handle
        title
        description
        descriptionHtml
        vendor
        images {
          id
          altText
          originalSrc
          src
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        variants {
          id
          price
          availableForSale
          displayName
          sku
          title
          inventoryQuantity
          inventoryPolicy
          barcode
        }
        collections {
          slug: handle
        }
      }
    }
  }
`;

export const useLatest = () => {
  const {
    allShopifyProduct: { nodes: latest },
  } = useStaticQuery(latestQuery);

  const products = latest.map(transform);

  return { loading: false, products };
}
