import { toKebabCase } from "~/utils/string";

export const transform = product => ({
  ...product,
  sku: Array.isArray(product.variants)
    ? product.variants[0]?.sku : product.slug,
  variants: (product?.variants || []).map(v => ({
    ...v,
    slug: toKebabCase(v.title),
  }))
});
