import styled from "styled-components";

import { IS_SMALL } from "~/utils/media";
import { HeroHeadline } from "~/components/typography";

export const HeadlineWrapper = styled.div`
  padding: 4rem 0 2rem;
  color: ${({ theme, $color }) =>
    theme.colors[$color] || 'inherit'};

  ${IS_SMALL} {
    padding: 2rem 0 1rem;

    ${HeroHeadline} {
      font-size: 48px;
    }
  }
`;
