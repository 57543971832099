import React from "react";

import { removeWidows } from "~/utils/string";
import { Grid } from "~/components/grid";
import { HeroHeadline } from "~/components/typography";

import { HeadlineWrapper } from "./headline-block.style";

export const HeadlineBlock = ({ value, color, children }) => (
  <Grid>
    <HeadlineWrapper $color={color}>
      <HeroHeadline>
        {removeWidows(value)}
      </HeroHeadline>
      {children}
    </HeadlineWrapper>
  </Grid>
);
HeadlineBlock.defaultProps = {
  value: "",
};
