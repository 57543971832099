import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  CarouselControlWrapper,
  CarouselControlArrowLeft,
  CarouselControlArrowRight,
} from './carousel.style';

export const CarouselControls = ({
  currentIndex,
  currentSize,
  offset,
  onNext,
  onPrev,
}) => (
  <CarouselControlWrapper $offset={offset}>
    <CarouselControlArrowLeft
      disabled={currentIndex === 0}
      onClick={onPrev}
    >
      <ChevronLeftIcon fontSize="large" />
    </CarouselControlArrowLeft>
    <CarouselControlArrowRight
      disabled={currentIndex === currentSize}
      onClick={onNext}
    >
      <ChevronRightIcon fontSize="large" />
    </CarouselControlArrowRight>
  </CarouselControlWrapper>
);
CarouselControls.propTypes = {
  currentIndex: PropTypes.number,
  currentSize: PropTypes.number,
  offset: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};
