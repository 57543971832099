import styled from "styled-components";

import { IS_MEDIUM, IS_SMALL } from "~/utils/media";
import { HeroHeadline } from "~/components/typography";

export const ProductGalleryGrid = styled.div`
  display: flex;
  flex-grow: 3;
  flex-wrap: wrap;
  width: 100%;

  ${IS_MEDIUM} {
    flex-grow: 2;
  }

  ${IS_SMALL} {
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
  }
`;

export const ProductGalleryHeader = styled.div`
  position: sticky;
  top: 60px; // navigation height
  display: block;
  flex-grow: 1;
  width: 30%;
  min-width: 280px;
  padding: 2rem 2rem 4rem 0;

  ${HeroHeadline} {
    position: relative;
    margin-bottom: 3rem;

    &:before {
      content: '';
      position: absolute;
      bottom: -1.5rem;
      width: 72px;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.accentTwo};
      border-radius: 48px;
    }
  }

  ${IS_SMALL} {
    position: relative;
    top: auto;
    padding: 1rem 1rem 2rem 0;

    ${HeroHeadline} {
      margin-bottom: 2rem;
    }
  }
`;

export const ProductGalleryWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 8rem auto;
  padding-top: 4rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 48px;
  }

  ${IS_MEDIUM} {
    max-width: 960px;
  }

  ${IS_SMALL} {
    flex-direction: column;
    margin-top: 6rem;
    margin-bottom: 2rem;
  }
`;

export const ProductGalleryItem = styled.div`
  width: 33.33%;
  padding: 0 0.5rem 1rem;

  ${IS_MEDIUM} {
    width: 50%;
  }
`;
