import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

import { CarouselControls } from './carousel-controls.component';
import {
  CarouselWrapper,
  CarouselItem,
} from './carousel.style';

export const Carousel = ({
  controls,
  items,
  options,
}) => {
  const [currentIndex, setCurrentIndex] = useState(options.initial || 0); // useRef(0);
  const [currentSize, setCurrentSize] = useState(options.length);
  const [sliderRef, slider] = useKeenSlider(
    Object.assign({
      centered: true,
      initial: 1,
      loop: true,
      slidesPerView: 3,
      spacing: 0,
      created(slider) {
        setCurrentSize(slider.details().size);
      },
      slideChanged(slider) {
        setCurrentIndex(slider.details().absoluteSlide);
      },
    }, options),
  );

  const onPrev = () => slider.prev();
  const onNext = () => slider.next();

  return (
    <CarouselWrapper ref={sliderRef}>
      {controls && (
        <CarouselControls
          {...controls}
          currentIndex={currentIndex}
          currentSize={currentSize}
          onPrev={onPrev}
          onNext={onNext}
        />
      )}
      {items.map((item, index) =>
        <CarouselItem key={`${item.key}:${index}`}>{item}</CarouselItem>
      )}
    </CarouselWrapper>
  );
};
Carousel.propTypes = {
  controls: PropTypes.shape({
    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  options: PropTypes.object,
};
