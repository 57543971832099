import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allShopifyProduct {
      nodes {
        id
        slug: handle
        title
        description
        descriptionHtml
        vendor
        vendorSlug
        images {
          id
          altText
          originalSrc
          src
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        collections {
          handle
        }
      }
    }
  }
`;

export const useDesigners = designer => {
  const {
    allShopifyProduct: { nodes } = { nodes: [] },
  } = useStaticQuery(query);
  const products = useMemo(() => {
    return nodes
      .filter(({ vendorSlug }) =>
        (designer && vendorSlug === designer) || !designer
      );
  }, [nodes, designer]);

  return { loading: false, products };
}
