import { graphql, useStaticQuery } from "gatsby";
import { transform } from "./products.utils";

const bestSellersQuery = graphql`
  {
    allShopifyProduct(
      limit: 6,
      filter: {
        tags: {
          in: "best seller"
        }
      },
      sort: {
        fields: publishedAt,
        order: DESC,
      },
    ) {
      nodes {
        id
        slug: handle
        title
        description
        descriptionHtml
        vendor
        images {
          id
          altText
          originalSrc
          src
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        collections {
          slug: handle
        }
      }
    }
  }
`;

export const useBestSellers = () => {
  const {
    allShopifyProduct: { nodes: bestSellers },
  } = useStaticQuery(bestSellersQuery);

  const products = bestSellers.map(transform);

  return { loading: false, products };
}
