import styled from "styled-components";

import { MEDIA_NO_TOUCH } from "~/utils/media";

export const CarouselWrapper = styled.div.attrs(() => ({
  className: "keen-slider",
}))``;

export const CarouselItem = styled.div.attrs(() => ({
  className: "keen-slider__slide",
}))``;

export const CarouselControlWrapper = styled.div`
  --arrow-offset: ${({ $offset }) => $offset || '0px'};

  overflow: visible;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: calc(50% + var(--arrow-offset));
  left: 0;
  width: 100%;
`;

export const CarouselControlArrow = styled.div`
  cursor: pointer;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  transition: all 80ms linear 0s;
  transition-property: color, background-color;

  svg {
    display: block;
    color: ${({ theme, disabled }) =>
      theme.colors[disabled ? 'neutralTwo' : 'primary']};
  }

  ${MEDIA_NO_TOUCH} {
    &:hover:not([disabled]) {
      background-color: ${({ theme }) => theme.colors.primary};

      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const CarouselControlArrowLeft = styled(CarouselControlArrow)`
  left: 0;
  transform: translate(-50%, -50%);
`;

export const CarouselControlArrowRight = styled(CarouselControlArrow)`
  right: 0;
  transform: translate(50%, -50%);
`;
