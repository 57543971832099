import React from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import { navigate } from "gatsby";

import { Grid } from "~/components/grid";
import { HeroHeadline } from "~/components/typography";
import { ProductItem } from "./../product-item";

import {
  ProductGalleryGrid,
  ProductGalleryWrapper,
  ProductGalleryHeader,
  ProductGalleryItem,
} from "./product-gallery.style";

export const ProductGallery = ({
  headline,
  layout,
  cta: {
    label,
    path,
  },
  products,
}) => (
  <Grid>
    <ProductGalleryWrapper {...{ layout }}>
      {headline && (
        <ProductGalleryHeader>
          <HeroHeadline color="accentTwo">{headline}</HeroHeadline>
          <Button
            variant="contained"
            onClick={() => navigate(path)}
          >
            {label}
          </Button>
        </ProductGalleryHeader>
      )}
      <ProductGalleryGrid>
        {products.map(product => (
          <ProductGalleryItem key={product.id}>
            <ProductItem {...product} />
          </ProductGalleryItem>
        ))}
      </ProductGalleryGrid>
    </ProductGalleryWrapper>
  </Grid>
);
ProductGallery.defaultProps = {
  products: [],
};
ProductGallery.propTypes = {
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  cta: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  }),
  layout: PropTypes.oneOf(['left', 'right']),
  ctaLabel: PropTypes.string,
};
